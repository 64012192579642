import { createTheme } from "@mui/material/styles";
import { darken } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#003D53",
      secondary: "#9FE7F3",
      yellow: "#F7AD19",
      blue: "#46ABC6",
      gray: "#F7F5F1",
      orange: "#EF8E02",
      white: "#FFFFFF",
    },
  },
  typography: {
    h1: {
      fontFamily: "Gogh-ExtraBold !important",
    },
    title: {
      fontFamily: "Gogh-ExtraBold !important",
    },
    subtitle: {
      fontFamily: "Sofia Pro Medium !important",
    },
    body: {
      fontFamily: "Sofia Pro Medium !important",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          background: "#EF8E02",
          borderRadius: "50px",
          color: "#fff",
          fontSize: "1.6rem",
          padding: "0 30px",
          height: "44px !important",
          "&:hover": {
            backgroundColor: darken("#EF8E02", 0.1),
          },
          fontFamily: "Sofia Pro Medium !important",
        },
      },
      defaultProps: {
        disableElevation: true,
        elevation: 0,
      },
    },
  },
});

export default theme;
